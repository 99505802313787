"use client"

import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Clock, Construction, X } from "lucide-react"

const useCountdown = (targetDate: string) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  return timeLeft
}

export default function Component() {
  const targetDate = "2024-10-20T15:00:00"
  const timeLeft = useCountdown(targetDate)
  const isEventStarted = Object.keys(timeLeft).length === 0
  const [showPopup, setShowPopup] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-400 to-blue-700">
    
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="text-center text-white p-8 rounded-lg shadow-2xl bg-blue-900 bg-opacity-50 backdrop-blur-md"
      >
        {isEventStarted ? (
          <motion.h1
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            className="text-4xl md:text-6xl font-bold mb-8"
          >
            The event has started!
          </motion.h1>
        ) : (
          <>
            <motion.h1
              animate={{ scale: [1, 1.1, 1] }}
              transition={{ duration: 2, repeat: Infinity }}
              className="text-3xl md:text-5xl font-bold mb-8 flex items-center justify-center"
            >
              <Construction className="mr-4" /> The Site is Under Construction
            </motion.h1>
            <div className="text-xl md:text-2xl mb-8 flex items-center justify-center">
              <Clock className="mr-2" /> Countdown to October 20, 2024, 03:00 PM
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="grid grid-cols-4 gap-4 text-center"
            >
              {Object.entries(timeLeft).map(([unit, value]) => (
                <div key={unit} className="bg-blue-800 bg-opacity-50 rounded-lg p-4">
                  <motion.div
                    key={String(value)}
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    className="text-3xl md:text-5xl font-bold"
                  >
                    {value as React.ReactNode}
                  </motion.div>
                  <div className="text-sm md:text-base uppercase">{unit}</div>
                </div>
              ))}
            </motion.div>
          </>
        )}
      </motion.div>
    </div>
  )
}
